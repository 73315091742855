import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {Link} from 'react-router-dom';
import {
  inputsTypes,
  inputsDefaultTypes,
  calculatedProductProptypes,
  calculatedProductDefaultProptypes
} from '../types';

import {
  formatCurrency, formatPercentage, formatMonthsToYears, getInitialRateType
} from '../lib/formats';
import { getFee } from '../lib/getFee';
import getInitialPeriod from '../lib/getInitialPeriod';
import getFirstInitialPeriod from '../lib/getFirstInitialPeriod';
import { setSelectedProduct } from '../actions/selectedProduct';
import { ProductType } from '../lib/enums';

const ProductDetail = ({
  filteredProduct, setModalToOpen, inputs, actions
}) => {
  function getCallbackRequestUrl() {
    return inputs.productType === ProductType.BUY_TO_LET ? './btl' : './residential';
  }

  return (
    <div className="modal">
      <div className="modal-wrapper products-detail">
        <button className="modal-close" type="button" aria-label="close" onClick={() => setModalToOpen(-1)} />
        <div className="products-detail-list">
          {filteredProduct.product.lender
            && (
            <img
              className="products-detail-list-logo"
              src={require(`../images/products/${filteredProduct.product.lender}.svg`)}
              alt={filteredProduct.product.lender}
            />
            )}
          <ul>
            <li>
              <div>
                <p>Monthly payment</p>
                <p className="products-detail-list-subtitle">
                  {`for the first ${getFirstInitialPeriod(filteredProduct.product.initialPeriodMonths, filteredProduct.product.initialPeriodLimit)} months`}
                </p>
              </div>
              <div className="products-detail-list-title">{formatCurrency(filteredProduct.initialMonthlyPayment)}</div>
            </li>
            <li>
              <p>Deal term</p>
              <p className="products-detail-list-title">{formatMonthsToYears(getInitialPeriod(filteredProduct.product.initialPeriodMonths))}</p>
            </li>
            <li>
              <div>
                <p>Subsequent variable rate</p>
              </div>
              <div className="products-detail-list-title">{formatPercentage(filteredProduct.product.variableRate)}</div>
            </li>
            <li>
              <div>Finance amount</div>
              <div className="products-detail-list-title">{formatCurrency(inputs.financeRequired)}</div>
            </li>
          </ul>
          <ul>
            <li>
              <div>Initial rate</div>
              <div className="products-detail-list-title">{formatPercentage(filteredProduct.product.initialRate)}</div>
            </li>
            <li>
              <div>Setup fees</div>
              <div className="products-detail-list-title">
                {getFee(
                  filteredProduct.product.fixedAmountFee,
                  filteredProduct.product.variableFee,
                  inputs.financeRequired
                )}
              </div>
            </li>
            <li>
              <div>Financing term</div>
              <div className="products-detail-list-title">{formatMonthsToYears(inputs.term)}</div>
            </li>
            <li>
              <div>Initial rate type</div>
              <div className="products-detail-list-title">{getInitialRateType(filteredProduct.product.initialRateType)}</div>
            </li>
          </ul>
        </div>
        <div className="products-detail-list-cta">
          <Link
            to={getCallbackRequestUrl()}
            className="button-action button-big"
            onClick={() => actions.setSelectedProduct(inputs, filteredProduct)}
          >
            CONTINUE
          </Link>
        </div>
        <div className="products-detail-list-disclaimer">
          <p>Representative Example</p>
          <p>
            {`Representative example: A Home Purchase Plan of ${formatCurrency(inputs.financeRequired)} over ${formatMonthsToYears(inputs.term)}. Total amount payable: ${formatCurrency(filteredProduct.totalAmountPayable)}. Includes rent of ${formatCurrency(filteredProduct.totalRentPayable)} and Administration fee of ${getFee(filteredProduct.product.fixedAmountFee, filteredProduct.product.variableFee, inputs.financeRequired)}.`}
            <br />
            {`Payments: ${getFirstInitialPeriod(filteredProduct.product.initialPeriodMonths, filteredProduct.product.initialPeriodLimit)} monthly payments of ${formatCurrency(filteredProduct.initialMonthlyPayment)} at ${formatPercentage(filteredProduct.product.initialRate)} ${getInitialRateType(filteredProduct.product.initialRateType)}, then ${inputs.term - getFirstInitialPeriod(filteredProduct.product.initialPeriodMonths, filteredProduct.product.initialPeriodLimit)} monthly payments of ${formatCurrency(filteredProduct.followOnPayment)} at ${formatPercentage(filteredProduct.product.variableRate)} variable.`}
          </p>
          <p className="highlight"><strong>Your home may be repossessed if you do not keep up repayments on your mortgage.</strong></p>
        </div>
      </div>
    </div>
  );
};

ProductDetail.propTypes = {
  filteredProduct: calculatedProductProptypes,
  setModalToOpen: PropTypes.func.isRequired,
  inputs: inputsTypes,
  actions: PropTypes.shape({
    setSelectedProduct: PropTypes.func,
  }),
};

ProductDetail.defaultProps = {
  filteredProduct: calculatedProductDefaultProptypes,
  inputs: inputsDefaultTypes,
  actions: {
    setSelectedProduct: () => {},
  },
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSelectedProduct,
  },
  dispatch)
});
export default connect(
  null,
  mapDispatchToProps
)(ProductDetail);
