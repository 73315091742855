import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Slider from 'react-rangeslider';
import Select from '../decorators/withSelectMe';
import Input from '../decorators/withInput';
import {
  inputsTypes, inputsDefaultTypes, calculatedProductsProptypes, calculatedProductsDefaultProptypes
} from '../types';
import { changeInput, changeProductType, changeFTV } from '../actions/inputs';
import {
  RepaymentType, ProductType, Purpose, ProductTypePurpose
} from '../lib/enums';
import { currencyToInt, monthsToYears } from '../lib/formats';
import { MenuContext } from '../App';

const Aside = ({
  inputs, actions, calculatedProducts
}) => {
  useEffect(() => {
    actions.changeFTV(inputs.propertyValue, inputs.financeRequired);
    // eslint-disable-next-line
  }, [inputs.propertyValue, inputs.financeRequired]);

  const options = [
    {
      value: 1,
      label: ProductTypePurpose.HOME_PURCHASE,
      productType: ProductType.RESIDENTIAL,
      purpose: Purpose.PURCHASE
    },
    {
      value: 2,
      label: ProductTypePurpose.HOME_REFINANCE,
      productType: ProductType.RESIDENTIAL,
      purpose: Purpose.REFINANCE
    },
    {
      value: 3,
      label: ProductTypePurpose.BUY_TO_LET,
      productType: ProductType.BUY_TO_LET,
      purpose: Purpose.PURCHASE_OR_REFINANCE
    }
  ];

  function handleChangeInput(value, name) {
    actions.changeInput(value, name);
  }

  function handleChangeInputCurrency(e, name) {
    handleChangeInput(currencyToInt(e.target.value), name);
  }

  function handleChangeProductType(e) {
    actions.changeProductType(e);
  }

  function getProductType() {
    if (inputs.productType === ProductType.RESIDENTIAL) {
      if (inputs.purpose === Purpose.PURCHASE) {
        return ProductTypePurpose.HOME_PURCHASE;
      }
      return ProductTypePurpose.HOME_REFINANCE;

    }
    return ProductTypePurpose.BUY_TO_LET;
  }

  function renderRepaymentType() {
    return inputs.productType === ProductType.BUY_TO_LET
      ? (
        <div className="form-field">
          <p className="form-field-title">Repayment type</p>
          <div className="form-radio">
            <input
              name="repayment-type"
              type="radio"
              id="hpp"
              value={RepaymentType.HPP}
              checked={inputs.repaymentType === RepaymentType.HPP}
              onChange={(e) => handleChangeInput(e.target.value, 'repaymentType')}
            />
            <label htmlFor="hpp">Rent and acquisition</label>
            <input
              name="repayment-type"
              type="radio"
              id="rent-only"
              value={RepaymentType.RENT_ONLY}
              checked={inputs.repaymentType === RepaymentType.RENT_ONLY}
              onChange={(e) => handleChangeInput(e.target.value, 'repaymentType')}
            />
            <label htmlFor="rent-only">Rent only</label>
          </div>
        </div>
      ) : null;

  }

  return (
    <aside className="aside">
      <MenuContext.Consumer>
        {(value) => (
          <div className="aside-content">
            {/* eslint-disable-next-line */}
            <button
              type="button"
              className="header-close aside-close"
              onClick={() => value.setClassName('')}
            />
            <h1 className="title-l">Enter your details</h1>
            <form className="form" action="#">
              <div className="form-field">
                <div className="form-field-wrapper">
                  <p className="form-field-title">Product type</p>
                  <p className="form-field-result">{`${calculatedProducts.length} Results`}</p>
                </div>
                <Select
                  options={options}
                  value={getProductType()}
                  onChange={(e) => handleChangeProductType(e)}
                />
              </div>
              <div className="form-field">
                <p className="form-field-title">Property value</p>
                <div className="form-input">
                  <span className="form-input-helper" />
                  <Input
                    onChange={(e) => handleChangeInputCurrency(e, 'propertyValue')}
                    type="tel"
                    minValue={10000}
                    maxValue={3000000}
                    value={inputs.propertyValue}
                  />
                </div>
              </div>
              <div className="form-field">
                <p className="form-field-title">Finance required</p>
                <div className="form-input">
                  <span className="form-input-helper" />
                  <Input
                    onChange={(e) => handleChangeInputCurrency(e, 'financeRequired')}
                    type="tel"
                    minValue={10000}
                    maxValue={3000000}
                    value={inputs.financeRequired}
                  />
                </div>
              </div>
              <div className="form-field">
                <p className="form-field-title">Term</p>
                <Slider
                  value={monthsToYears(inputs.term)}
                  onChange={(value) => handleChangeInput(value * 12, 'term')}
                  min={5}
                  max={35}
                  step={1}
                  tooltip={false}
                  handleLabel={`${monthsToYears(inputs.term)} yrs`}
                />
                <p className="rangeslider-legend">
                  <span>5 yrs</span>
                  <span>35 yrs</span>
                </p>
              </div>
              {renderRepaymentType()}
              <div className="aside-cta">
                <button
                  type="button"
                  className="button-action button-big"
                  onClick={() => value.setClassName('')}
                >
                  UPDATE RESULTS
                </button>
              </div>
            </form>
          </div>
        )}

      </MenuContext.Consumer>
    </aside>
  );
};

Aside.propTypes = {
  inputs: inputsTypes,
  actions: PropTypes.shape({
    changeInput: PropTypes.func,
    changeProductType: PropTypes.func,
    changeFTV: PropTypes.func
  }),
  calculatedProducts: calculatedProductsProptypes,

};

Aside.defaultProps = {
  inputs: inputsDefaultTypes,
  actions: {
    changeInput: () => {},
    changeProductType: () => {},
    changeFTV: () => {}
  },
  calculatedProducts: calculatedProductsDefaultProptypes,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    changeInput,
    changeProductType,
    changeFTV
  },
  dispatch)
});

const mapStateToProps = (state) => ({
  inputs: state.inputs,
  calculatedProducts: state.calculatedProducts
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(Aside);
