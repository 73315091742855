import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { setUrlParams } from '../actions/inputs';
import { setUrlProductType } from '../actions/selectedProduct';
import Products from './Products';
import Aside from './Aside';

const Calculator = ({
  actions, location, match
}) => {
  useEffect(() => {
    const url = match.path.substr(1);
    const params = queryString.parse(location.search);
    actions.setUrlParams(url, params);
    actions.setUrlProductType(url);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="calculator">
      <Aside />
      <Products />
    </div>
  );
};

Calculator.propTypes = {
  actions: PropTypes.shape({
    setUrlParams: PropTypes.func,
    setUrlProductType: PropTypes.func
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Calculator.defaultProps = {
  actions: {
    setUrlProductType: () => {},
    setUrlParams: () => {}
  },
  match: {
    path: null
  },
  location: {
    search: null
  },
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setUrlParams,
    setUrlProductType
  },
  dispatch)
});

export default connect(
  null, mapDispatchToProps
)(Calculator);
