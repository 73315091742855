import { Purpose, ProductType, RepaymentType } from '../lib/enums';

export default (state = {}, action) => {
  switch (action.type) {
    case 'INPUTS.SET_URL_PARAMS': {
      let purpose;
      let productType;
      let term;
      let financeRequired;
      let propertyValue;
      let repaymentType;
      if (action.params) {
        term = parseInt(action.params.term, 10)
          || state.term;
        financeRequired = parseInt(action.params.financeAmount, 10)
          || state.financeRequired;
        propertyValue = parseInt(action.params.propertyPrice, 10)
          || state.propertyValue;
        repaymentType = Object.values(RepaymentType).indexOf(action.params.repaymentType) >= 0
          ? action.params.repaymentType
          : state.repaymentType;
        purpose = Object.values(Purpose).indexOf(action.params.purpose) >= 0
          ? action.params.purpose
          : state.purpose;
      }

      switch (action.url) {
        case 'buy-to-let': {
          purpose = Purpose.PURCHASE;
          productType = ProductType.BUY_TO_LET;
          break;
        }
        case 'home-purchase': {
          purpose = Purpose.PURCHASE;
          productType = ProductType.RESIDENTIAL;
          break;
        }
        case 'refinance': {
          purpose = Purpose.REFINANCE;
          productType = ProductType.RESIDENTIAL;
          break;
        }
        default: {
          purpose = state.purpose;
          productType = state.productType;
        }
      }

      return {
        ...state,
        purpose,
        repaymentType,
        term,
        financeRequired,
        propertyValue,
        productType,
      };
    }
    case 'INPUTS.CHANGE_INPUT': {
      return {
        ...state,
        [action.name]: action.value
      };
    }
    case 'INPUTS.CHANGE_PRODUCT_TYPE': {
      return {
        ...state,
        productType: action.payload.productType,
        purpose: action.payload.purpose
      };
    }
    case 'INPUTS.CHANGE_LOCATION': {
      return {
        ...state,
        location: action.payload,
      };
    }
    case 'INPUTS.CHANGE_FTV': {
      return {
        ...state,
        fTV: action.financeRequired / action.propertyValue,
      };
    }
    default:
      return state;
  }
};
