import React from 'react';

import { intToCurrency } from '../lib/formats';

const withInput = () => (props) => {
  // eslint-disable-next-line react/prop-types
  const { minValue, maxValue, ...newProps } = props;
  const className = newProps.value > maxValue || newProps.value < minValue
    ? 'error'
    : '';
  return (
    <input
      {...newProps}
      className={className}
      value={intToCurrency(newProps.value)}
    />
  );
};

export default withInput();
