import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { validatePhone, validateEmail } from '../lib/validationForms';
import { changeInput } from '../actions/selectedProduct';
import { ProductType, Appointment } from '../lib/enums';
import {
  FORM_ENDPOINT, FORM_ENDPOINT_DEV, LOCAL_DOMAIN, DEV_DOMAIN
} from '../lib/consts';

const CallbackRequest = ({
  actions, selectedProduct, type, history
}) => {
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [validName, setValidName] = useState(false);
  const [pristine, setPristine] = useState(true);
  const lender = (selectedProduct.product && selectedProduct.product.lenderName) || '';

  useEffect(() => {
      actions.changeInput(Appointment.ASAP, 'callRequest');
    // eslint-disable-next-line
  }, []);

  function handleChangeInput(value, name) {
    actions.changeInput(value, name);
  }

  function handleChangePhone(value, name) {
    handleChangeInput(value, name);
    setValidPhone(validatePhone(value));
  }

  function handleChangeEmail(value, name) {
    handleChangeInput(value, name);
    setValidEmail(validateEmail(value));
  }

  function handleChangeText(value, name) {
    handleChangeInput(value, name);
    setValidName(value.length > 5);
  }

  function renderInputClassname(input) {
    switch (input) {
      case 'email': {
        return (pristine || validEmail) ? '' : 'error';
      }
      case 'phone': {
        return (pristine || validPhone) ? '' : 'error';
      }
      case 'name': {
        return (pristine || validName) ? '' : 'error';
      }
      default: {
        return '';
      }
    }
  }

  function renderFormError() {
    return !(validEmail && validPhone && validName);
  }

  function sendData(e) {
    e.preventDefault();
    const endpoint = (window.location.host === DEV_DOMAIN || window.location.host === LOCAL_DOMAIN) ? FORM_ENDPOINT_DEV : FORM_ENDPOINT;

    if (pristine) setPristine(false);

    if (!renderFormError()) {
      const { lenderName, ...newProduct } = selectedProduct.product;
      const { product, ...newSelectedProduct } = selectedProduct;
      const formValues = {
        product: newProduct,
        ...newSelectedProduct
      };

      fetch(endpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });
      history.push(`./success?type=${type}`);
    }
  }

  function renderForm() {
    return (
      <>
        {!pristine && renderFormError() && <div className="form-errorMessage">Oops! That form is not valid.</div>}
        <form noValidate className="form" action="./success" method="POST" onSubmit={(e) => sendData(e)}>
          <div className="form-field">
            <div className={`form-input ${renderInputClassname('name')}`}>
              <input
                name="name"
                placeholder="Full name"
                onChange={(e) => handleChangeText(e.target.value, 'name')}
                type="text"
                value={selectedProduct.name}
              />
            </div>
          </div>
          <div className="form-field">
            <div className={`form-input ${renderInputClassname('email')}`}>
              <input
                name="email"
                placeholder="Email"
                onChange={(e) => handleChangeEmail(e.target.value, 'email')}
                type="email"
                value={selectedProduct.email}
              />
            </div>
          </div>
          <div className="form-field">
            <div className={`form-input ${renderInputClassname('phone')}`}>
              <input
                name="phone-number"
                placeholder="Telephone number"
                onChange={(e) => handleChangePhone(e.target.value, 'phone')}
                type="tel"
                value={selectedProduct.phone}
              />
            </div>
          </div>
          <div className="form-field form-field-appointment">
            <p className="form-field-title">What time is good for you?</p>
            <div className="form-radio">
              <input
                name="appointment"
                type="radio"
                id="soon"
                value={Appointment.ASAP}
                checked={selectedProduct.callRequest === Appointment.ASAP}
                onChange={(e) => handleChangeInput(e.target.value, 'callRequest')}
              />
              <label htmlFor="soon">As soon as possible</label>
              <input
                name="appointment"
                type="radio"
                id="morning"
                value={Appointment.MORNING}
                checked={selectedProduct.callRequest === Appointment.MORNING}
                onChange={(e) => handleChangeInput(e.target.value, 'callRequest')}
              />
              <label htmlFor="morning">Morning</label>
              <input
                name="appointment"
                type="radio"
                id="afternoon"
                value={Appointment.AFTERNOON}
                checked={selectedProduct.callRequest === Appointment.AFTERNOON}
                onChange={(e) => handleChangeInput(e.target.value, 'callRequest')}
              />
              <label htmlFor="afternoon">Afternoon</label>
            </div>
          </div>
          <button
            type="submit"
            disabled={!pristine && renderFormError()}
            className="button-action products-list-cta"
          >
            Submit
          </button>
        </form>
        <p>
          Clicking Submit accepts both <a target="_blank" rel="noopener noreferrer" href="https://www.islamicfinanceguru.com/privacy-policy">IFG's</a> and <a target="_blank" rel="noopener noreferrer" href="https://preceptfinance.co.uk/privacy-policy/">Precept Finance's</a> Privacy Policies.
        </p>
      </>
    );
  }

  if (type === ProductType.BRIDGING) {
    return (
      <div className="form-wrapper">
        <h1 className="title-xxl">Request a callback</h1>
        <p className="form-wrapper-subtitle-left">Let&apos;s talk about your bridge finance requirements.</p>
        <ol>
          <li>Quick responses</li>
          <li>Up to 75% LTV</li>
          <li>Residential or commercial property</li>
        </ol>
        {renderForm()}
      </div>
    );
  }
  return (
    <div className="form-wrapper">
      <h1 className="title-xxl">Request a callback</h1>
      <p className="form-wrapper-subtitle">Speak to one of Precept Finance's underwriting experts to discuss your finance requirements.</p>
      {renderForm()}
    </div>
  );
};


CallbackRequest.propTypes = {
  type: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  selectedProduct: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    product: PropTypes.shape({
      lenderName: PropTypes.string
    }),
    callRequest: PropTypes.string
  }),
  actions: PropTypes.shape({
    changeInput: PropTypes.func,
  }),
  location: PropTypes.shape({
    host: PropTypes.string,
  }),
};

CallbackRequest.defaultProps = {
  selectedProduct: {},
  history: () => {},
  actions: {
    changeInput: () => {},
  },
  location: {
    host: null,
  }
};

const mapStateToProps = (state) => ({
  selectedProduct: state.selectedProduct
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    changeInput,
  },
  dispatch)
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(CallbackRequest));
