import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ProductType, Purpose } from '../lib/enums';
import { changeProductType } from '../actions/inputs';

const ProductTypePurposeSelect = ({ actions }) => {
  function handleClick(productType, purpose) {
    actions.changeProductType({productType, purpose});
  }

  return (
    <article className="productSelector">
      <div className="productSelector-wrapper">
        <h1 className="title-xxl">Let’s find the right product for you.</h1>
        <p className="productSelector-subtitle">Chose one</p>
        <ul className="productSelector-list">
          <li>
            <Link
              to="product-location"
              onClick={() => handleClick(ProductType.RESIDENTIAL, Purpose.PURCHASE)}
            >
              Home purchase
            </Link>
          </li>
          <li>
            <Link
              to="product-location"
              onClick={() => handleClick(ProductType.RESIDENTIAL, Purpose.REFINANCE)}
            >
              Home refinance
            </Link>
          </li>
          <li>
            <Link to="buy-to-let">Buy-to-let</Link>
          </li>
        </ul>
      </div>
    </article>
  );
};

ProductTypePurposeSelect.propTypes = {
  actions: PropTypes.shape({
    changeProductType: PropTypes.func
  }),
};

ProductTypePurposeSelect.defaultProps = {
  actions: {
    changeProductType: () => {}
  },
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    changeProductType
  },
  dispatch)
});


export default connect(
  null, mapDispatchToProps
)(ProductTypePurposeSelect);
