import { combineReducers } from 'redux';
import products from './products';
import inputs from './inputs';
import calculatedProducts from './calculatedProducts';
import selectedProduct from './selectedProduct';

const ifgApp = combineReducers({
  products,
  inputs,
  calculatedProducts,
  selectedProduct
});

export default ifgApp;
