import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { setPresetBridgingProduct } from '../actions/selectedProduct';
import { ProductType } from '../lib/enums';

import CallbackRequest from './CallbackRequest';


const BridgingCallbackRequest = ({
  actions
}) => {
  useEffect(() => {
    actions.setPresetBridgingProduct();
    // eslint-disable-next-line
  }, []);

  return <CallbackRequest type={ProductType.BRIDGING} />;
};

BridgingCallbackRequest.propTypes = {
  actions: PropTypes.shape({
    setPresetBridgingProduct: PropTypes.func,
  }),
};

BridgingCallbackRequest.defaultProps = {
  actions: {
    setPresetBridgingProduct: () => {},
  },
};

const mapStateToProps = (state) => ({
  selectedProduct: state.selectedProduct
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setPresetBridgingProduct,
  },
  dispatch)
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(BridgingCallbackRequest));
