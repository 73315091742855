export default {
  inputs: {
    productType: "RESIDENTIAL",
    purpose: "PURCHASE",
    repaymentType: "HPP",
    propertyValue: 200000,
    financeRequired: 100000,
    fTV: 0.5,
    term: 360,
  },
  selectedProduct: {
    name: "",
    email: "",
    phone: "",
    callRequest: null,
  },
  products: [
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 2 year fixed, 85% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.85,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0724,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 2 year fixed, 80% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0659,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: null,
      lenderName: null,
      productName: 'StrideUp HPP, 2 year fixed, 65% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0639,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 5 year fixed, 85% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0664,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 5 year fixed, 80% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0619,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 5 year fixed, 65% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.65,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0609,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 10 year fixed, 85% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.85,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0659,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 10 year fixed, 80% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0614,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'STRIDE_UP',
      lenderName: 'StrideUp',
      productName: 'StrideUp HPP, 10 year fixed, 65% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0604,
      initialRateType: 'FIXED',
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed Green Product 65% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0589,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed Green Product 80% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0599,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed Green Product 90% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0649,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed Green Product 95% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.95,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0689,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed Green Product 65% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0585,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed Green Product 80% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0595,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed Green Product 90% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0639,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed Green Product 95% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.95,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0679,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed, 65% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0599,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed, 80% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0609,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed, 90% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0659,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed, 65% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0595,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed, 80% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0605,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed, 90% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0649,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed, 95% FTV',
      purpose: 'PURCHASE',
      productType: 'RESIDENTIAL',
      repaymentType: 'HPP',
      fTV: 0.95,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0689,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed Green Product 65% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0614,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed Green Product 80% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0634,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed Green Product 65% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.065,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed Green Product 80% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0664,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed, 65% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0624,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 2 years fixed, 80% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0644,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed, 65% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.066,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    },
    {
      lender: 'GATEHOUSE BANK',
      lenderName: 'Gatehouse Bank',
      productName: 'Gatehouse Bank, 5 years fixed, 80% FTV',
      purpose: 'PURCHASE',
      productType: 'BUY TO LET',
      repaymentType: 'BTL',
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0674,
      initialRateType: 'FIXED',
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: 'ENGLAND_WALES'
    }
  ]
};
