import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import Calculator from './components/Calculator';
import Success from './components/Success';
import Header from './components/Header';
import Error404 from './components/Error404';
import Footer from './components/Footer';
import ProductTypePurposeSelect from './components/ProductTypePurposeSelect';
import ProductLocation from './components/ProductLocation';
import CallbackRequest from './components/CallbackRequest';
import BridgingCallbackRequest from './components/BridgingCallbackRequest';
import { ProductType } from './lib/enums';

export const MenuContext = React.createContext();
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const App = () => {
  const [modalClassname, setModalClassname] = useState('');
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <MenuContext.Provider
      value={{
        setClassName: setModalClassname,
      }}
    >
      <div className={`app ${modalClassname}`}>
        <Header />
        <div className="layout">
          <Router history={history}>
            <Switch>
              <Route
                exact
                path="/success"
                component={Success}
              />
              <Route
                exact
                path="/btl"
                component={() => <CallbackRequest type={ProductType.BUY_TO_LET} />}
              />
              <Route
                exact
                path="/residential"
                component={() => <CallbackRequest type={ProductType.RESIDENTIAL} />}
              />
              <Route
                exact
                path="/product-type-purpose"
                component={ProductTypePurposeSelect}
              />
              <Route
                exact
                path="/product-location"
                component={ProductLocation}
              />
              <Route
                exact
                path={['/buy-to-let', '/home-purchase', '/refinance']}
                component={Calculator}
              />
              <Route
                exact
                path="/bridging"
                component={() => <BridgingCallbackRequest type={ProductType.BRIDGING} />}
              />
              <Route exact path="/" component={Calculator} />
              <Route component={Error404} />
            </Switch>
          </Router>
        </div>
        <Footer />
      </div>
    </MenuContext.Provider>
  );
};

export default App;
