export const ProductType = {
  RESIDENTIAL: 'RESIDENTIAL',
  BUY_TO_LET: 'BUY_TO_LET',
  BRIDGING: 'BRIDGING'
};

export const Purpose = {
  PURCHASE: 'PURCHASE',
  REFINANCE: 'REFINANCE',
  PURCHASE_OR_REFINANCE: 'PURCHASE_OR_REFINANCE'
};


export const ProductTypePurpose = {
  HOME_PURCHASE: 'Home purchase',
  HOME_REFINANCE: 'Home refinance',
  BUY_TO_LET: 'Buy-to-let'
};

export const RepaymentType = {
  HPP: 'HPP',
  RENT_ONLY: 'RENT_ONLY'
};

export const Locations = {
  LONDON_AND_HOME_COUNTIES: 'LONDON_AND_HOME_COUNTIES',
  ELSEWHERE: 'ELSEWHERE',
  PRIME_CENTRAL_LON_OUTER_LON: 'PRIME_CENTRAL_LON_OUTER_LON',
  ENGLAND_SCOTLAND_WALES: 'ENGLAND_SCOTLAND_WALES',
  ENGLAND_WALES: 'ENGLAND_WALES',
  HOME_COUNTIES: 'HOME_COUNTIES',
};

export const Appointment = {
  MORNING: 'MORNING',
  LUNCH: 'LUNCH',
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING',
  ASAP: 'ASAP',
};
