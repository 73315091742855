import { getRawFee } from '../lib/getFee';
import { ProductType } from '../lib/enums';


export default (state = {}, action) => {
  switch (action.type) {
    case 'SELECTED_PRODUCT.SET_PRESET_BRIDGING_PRODUCT': {
      return {
        ...state,
        product: {
          lenderName: 'Offa',
          productName: 'Offa Bridging',
          productType: ProductType.BRIDGING,
          minTerm: 1,
          maxTerm: 12
        }
      };
    }
    case 'SELECTED_PRODUCT.CHANGE_INPUT': {
      return {
        ...state,
        [action.name]: action.value
      };
    }
    case 'SELECTED_PRODUCT.SET_URL_PRODUCT_TYPE': {
      return {
        ...state,
        example: {
          ...state.example,
          urlProductType: action.url
        }
      };
    }
    case 'SELECTED_PRODUCT.SET_SELECTED_PRODUCT': {
      const {
        lenderName,
        productName,
        purpose,
        productType,
        repaymentType,
        fTV,
        initialPeriodMonths,
        initialPeriodLimit,
        initialRate,
        initialRateType,
        minTerm,
        maxTerm,
        variableRate,
        fixedAmountFee,
        variableFee,
        minFinance,
        maxFinance,
        minPropertyValue,
        location
      } = action.calculatedProduct.product;

      const {
        initialMonthlyPayment,
        followOnPayment,
        totalRentPayable,
        totalAmountPayable
      } = action.calculatedProduct;

      const {
        propertyValue,
        financeRequired,
        term,
      } = action.inputs;

      return {
        ...state,
        product: {
          lenderName,
          productName,
          purpose,
          productType,
          repaymentType,
          fTV,
          initialPeriodMonths,
          initialPeriodLimit,
          initialRate,
          initialRateType,
          minTerm,
          maxTerm,
          variableRate,
          fixedAmountFee,
          variableFee,
          minFinance,
          maxFinance,
          minPropertyValue,
          location
        },
        example: {
          ...state.example,
          propertyPrice: propertyValue,
          financeAmount: financeRequired,
          deposit: propertyValue - financeRequired,
          term,
          fee: getRawFee(fixedAmountFee, variableFee, financeRequired),
          initialMonthlyPmt: initialMonthlyPayment,
          variableMonthlyPmt: followOnPayment,
          totalRent: totalRentPayable,
          totalCost: totalAmountPayable,
        }
      };
    }
    default:
      return state;
  }
};
