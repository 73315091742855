import PMT from './PMT';

export default function getFormulaResults(
  financingAmount,
  fees,
  rentAndAcquisition,
  initialRate,
  followOnRate,
  term
) {
  const FIXED_RATE_PERIOD = 24;

  const formulaArray = [{
    balance: financingAmount * -1,
    totalCashFlows: (financingAmount * -1) + fees,
  }];

  for (let i = 1; i <= term; i += 1) {
    const rate = i <= FIXED_RATE_PERIOD ? initialRate : followOnRate;
    const monthlyPayment = rentAndAcquisition
      ? PMT((rate / 12), (term - (i - 1)), formulaArray[i - 1].balance)
      : (formulaArray[i - 1].balance * -1) * (rate / 12);
    const rent = ((formulaArray[i - 1].balance * -1) * rate) / 12;
    const acquisition = monthlyPayment - rent;
    const balance = formulaArray[i - 1].balance + acquisition;
    const totalCashFlows = monthlyPayment;

    formulaArray.push({
      balance,
      rate,
      monthlyPayment,
      rent,
      acquisition,
      totalCashFlows
    });
  }

  const initialMonthlyPayment = formulaArray[1].monthlyPayment;
  const followOnPayment = formulaArray[formulaArray.length - 1].monthlyPayment;
  const totalRentPayable = initialMonthlyPayment
    * FIXED_RATE_PERIOD
    + followOnPayment
    * (term - FIXED_RATE_PERIOD);
  const totalAmountPayable = totalRentPayable
    + fees
    + (!rentAndAcquisition ? financingAmount : 0);

  return {
    initialMonthlyPayment,
    followOnPayment,
    totalRentPayable,
    totalAmountPayable,
  };
}
