export const setUrlParams = (url, params) => ({
  type: 'INPUTS.SET_URL_PARAMS',
  url,
  params
});

export const changeInput = (value, name) => ({
  type: 'INPUTS.CHANGE_INPUT',
  value,
  name
});

export const changeProductType = (payload) => ({
  type: 'INPUTS.CHANGE_PRODUCT_TYPE',
  payload,
});

export const changeLocation = (payload) => ({
  type: 'INPUTS.CHANGE_LOCATION',
  payload,
});

export const changeFTV = (propertyValue, financeRequired) => ({
  type: 'INPUTS.CHANGE_FTV',
  propertyValue,
  financeRequired
});
