import { PhoneNumberUtil } from 'google-libphonenumber';


export function validatePhone(phone) {
  /*
  Phone number validation using google-libphonenumber
  */
  let valid = false;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    valid = phoneUtil.isValidNumber(phoneUtil.parse(phone, 'GB'));
  } catch (e) {
    valid = false;
  }
  return valid;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
