function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export default function getFirstInitialPeriod(initialPeriodMonths, initialPeriodLimit) {
  if (initialPeriodLimit) {
    const currentDate = new Date();
    const date = new Date(initialPeriodLimit);
    return monthDiff(currentDate, date);
  }
  return initialPeriodMonths;
}
