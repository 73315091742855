import Stream from 'streamjs';
import { Purpose, RepaymentType } from './enums';
import getFormulaResults from './getFormulaResults';


export default function getFilteredProducts(products, inputs) {
  const s = Stream(products)
    .filter(({ productType: inputs.productType }))
    .filter((product) => product.purpose === inputs.purpose
      || product.purpose === Purpose.PURCHASE_OR_REFINANCE)
    .filter(({ repaymentType: inputs.repaymentType }))
    .filter((product) => product.fTV >= inputs.fTV)
    .filter((product) => {
      const prodMin = Number(product.minFinance) || 0;
      const prodMax = Number(product.maxFinance) || 0;
      const inputFin = Number(inputs.financeRequired) || 0;
      return prodMin <= inputFin && prodMax >= inputFin;
    })
    .filter((product) => {
      const prodMin = Number(product.minTerm) || 0;
      const prodMax = Number(product.maxTerm) || 0;
      const inputTerm = Number(inputs.term) || 0;
      console.log(inputTerm);
      console.log(prodMin, prodMax);
      console.log(product.lender, prodMin <= inputTerm && prodMax >= inputTerm);
      return prodMin <= inputTerm && prodMax >= inputTerm;
    })
    .groupBy((prod) => `${prod.lender} ${prod.initialRateType} ${prod.initialPeriodMonths} ${prod.fixedAmountFee == null ? prod.variableFee : prod.fixedAmountFee}`);

  return Stream(Object.values(s).map((lenders) => lenders.reduce((acc, currentValue) => (acc.fTV <= currentValue.fTV ? acc : currentValue))))
    .map((filteredProduct) => {
      const formulaResults = getFormulaResults(
        inputs.financeRequired,
        filteredProduct.fixedAmountFee,
        inputs.repaymentType === RepaymentType.HPP,
        filteredProduct.initialRate,
        filteredProduct.variableRate,
        inputs.term
      );
      return ({
        product: filteredProduct,
        ...formulaResults
      });
    })
    .sorted((calculatedProductA, calculatedProductB) => {
      if (calculatedProductA.initialMonthlyPayment === calculatedProductB.initialMonthlyPayment) {
        if (calculatedProductA.product.fTV === calculatedProductB.product.fTV) {
          return 0;
        }
        if (calculatedProductA.product.fTV < calculatedProductB.product.fTV) {
          return -1;
        }
        return 1;
      }
      if (calculatedProductA.initialMonthlyPayment < calculatedProductB.initialMonthlyPayment) {
        return -1;
      }
      return 1;
    })
    .toArray();
}
