import PropTypes from 'prop-types';

export const inputsTypes = PropTypes.shape({
  productType: PropTypes.string,
  purpose: PropTypes.string,
  repaymentType: PropTypes.string,
  propertyValue: PropTypes.number,
  financeRequired: PropTypes.number,
  fTV: PropTypes.number,
  term: PropTypes.number,
});

export const inputsDefaultTypes = {
  productType: null,
  purpose: null,
  repaymentType: null,
  propertyValue: null,
  financeRequired: null,
  fTV: null,
  term: null,
};

export const productTypes = PropTypes.shape({
  logo: PropTypes.string,
  productName: PropTypes.string,
  purpose: PropTypes.string,
  productType: PropTypes.string,
  repaymentType: PropTypes.string,
  fTV: PropTypes.number,
  initialPeriodMonths: PropTypes.number,
  initialPeriodLimit: PropTypes.string,
  initialRate: PropTypes.number,
  initialRateType: PropTypes.string,
  minTerm: PropTypes.number,
  maxTerm: PropTypes.number,
  variableRate: PropTypes.number,
  fixedAmountFee: PropTypes.number,
  variableFee: PropTypes.number,
  minFinance: PropTypes.number,
  maxFinance: PropTypes.number,
  minPropertyValue: PropTypes.number,
  location: PropTypes.string,
});

export const productDefaultTypes = {
  logo: null,
  productName: null,
  purpose: null,
  productType: null,
  repaymentType: null,
  fTV: null,
  initialPeriodMonths: null,
  initialPeriodLimit: null,
  initialRate: null,
  initialRateType: null,
  minTerm: null,
  maxTerm: null,
  variableRate: null,
  fixedAmountFee: null,
  variableFee: null,
  minFinance: null,
  maxFinance: null,
  minPropertyValue: null,
  location: null,
};
export const productsTypes = PropTypes.arrayOf(productTypes);

export const productsDefaultTypes = [productDefaultTypes];

export const calculatedProductProptypes = PropTypes.shape({
  product: productTypes,
  followOnPayment: PropTypes.number,
  initialMonthlyPayment: PropTypes.number,
  totalAmountPayable: PropTypes.number,
  totalRentPayable: PropTypes.number,
});

export const calculatedProductsProptypes = PropTypes.arrayOf(calculatedProductProptypes);

export const calculatedProductDefaultProptypes = {
  product: productDefaultTypes,
  followOnPayment: null,
  initialMonthlyPayment: null,
  totalAmountPayable: null,
  totalRentPayable: null,
};


export const calculatedProductsDefaultProptypes = [calculatedProductDefaultProptypes];
