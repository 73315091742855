import React from 'react';
import { IFG_URL } from '../lib/consts';

const Error404 = () => (
  <div className="success">
    <h1 className="title-xxl">Oops!</h1>
    <p>We can't seem to find the page you're looking for.</p>
    <p><strong>Error code: 404</strong></p>
    <a
      href={IFG_URL}
      className="button-action success-button"
    >
        BACK TO ISLAMIC FINANCE GURU
    </a>
  </div>
);

export default Error404;
