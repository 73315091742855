import React from 'react';
import Select from 'react-select-me';

const withSelectMe = () => ({ ...props }) => (
  <Select
    {...props}
    listRenderer={(o, s, optionRenderer, onChange) => (
      <ul className="dd__list">
        {o.map((option) => (
          <li
            className={option.label === s[0].label ? 'dd__option selected' : 'dd__option'}
            onClick={onChange(option)}
            key={option.value}
          >
            {option.label}
          </li>
        ))}
      </ul>
    )}
  />
);

export default withSelectMe(Select);
