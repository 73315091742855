import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Locations } from '../lib/enums';
import { changeLocation } from '../actions/inputs';

const ProductLocation = ({ actions }) => {
  function handleClick(location) {
    actions.changeLocation(location);
  }

  return (
    <article className="productSelector">
      <div className="productSelector-wrapper">
        <h1 className="title-xxl">Where&apos;s the property you want to Purchase?</h1>
        <ul className="productSelector-list">
          <li>
            <Link
              to="./"
              onClick={() => handleClick(Locations.LONDON_AND_HOME_COUNTIES)}
            >
              London and the Home Counties
            </Link>
          </li>
          <li>
            <Link
              to="./"
              onClick={() => handleClick(Locations.ELSEWHERE)}
            >
              Elsewhere
            </Link>
          </li>
        </ul>
      </div>
    </article>
  );
};

ProductLocation.propTypes = {
  actions: PropTypes.shape({
    changeLocation: PropTypes.func
  }),
};

ProductLocation.defaultProps = {
  actions: {
    changeLocation: () => {}
  },
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    changeLocation
  },
  dispatch)
});

export default connect(
  null, mapDispatchToProps
)(ProductLocation);
