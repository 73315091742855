export const setSelectedProduct = (inputs, calculatedProduct) => ({
  type: 'SELECTED_PRODUCT.SET_SELECTED_PRODUCT',
  inputs,
  calculatedProduct
});

export const changeInput = (value, name) => ({
  type: 'SELECTED_PRODUCT.CHANGE_INPUT',
  value,
  name
});

export const setUrlProductType = (url) => ({
  type: 'SELECTED_PRODUCT.SET_URL_PRODUCT_TYPE',
  url
});

export const setPresetBridgingProduct = () => ({
  type: 'SELECTED_PRODUCT.SET_PRESET_BRIDGING_PRODUCT'
});

export default setSelectedProduct;
