import React from 'react';

const Footer = () => (
  <footer className="footer">
    <p>This information is a simple comparison service. Listings are provided in partnership by Islamicfinanceguru Limited and Precept Finance Ltd on a non-advised basis and Islamicfinanceguru does not receive payment for each lead generated.</p>
    <p>This means that no advice is given or implied and you are solely responsible for deciding whether the product is suitable for your needs. If you are not sure which is the right product for you, you should seek advice.</p>
    <p>The table shows a range of products from the market and this service service does not consider your personal credit position.</p>
    <p>You can review the
      {' '}
      <a target="_blank" rel="noopener noreferrer" href="https://www.islamicfinanceguru.com/privacy-policy">IFG Privacy Policy</a>
      {' '}
and the
      {' '}
      <a target="_blank" rel="noopener noreferrer" href="https://preceptfinance.co.uk/privacy-policy/">Precept Finance Privacy Policy</a>
.
    </p>
  </footer>
);

export default Footer;
