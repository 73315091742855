import React from 'react';
import logo from '../images/IFG-Logo-Precept.svg';

const Header = () => (
  <header className="header">
    <img className="header-logo" src={logo} alt="Islamic Finance Guru" />
    {/* eslint-disable-next-line */}
    {/*<a className="header-close" href={IFG_URL} /> */}
  </header>
);

export default Header;
