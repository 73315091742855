import { formatCurrency } from './formats';

export function getRawFee(fixed, variable, financeRequired) {
  if (fixed && variable) {
    return Math.max(fixed, variable * financeRequired);
  }
  return fixed || variable * financeRequired;
}

export function getFee(fixed, variable, financeRequired) {
  return formatCurrency(getRawFee(fixed, variable, financeRequired));
}
