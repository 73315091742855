import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import getFilteredProducts from '../lib/getFilteredProducts';
import {
  formatCurrency, formatPercentage, formatMonthsToYears, getInitialRateType
} from '../lib/formats';
import { getFee } from '../lib/getFee';
import getInitialPeriod from '../lib/getInitialPeriod';
import getFirstInitialPeriod from '../lib/getFirstInitialPeriod';
import {
  inputsTypes,
  inputsDefaultTypes,
  productsTypes,
  productsDefaultTypes,
  calculatedProductsProptypes,
  calculatedProductsDefaultProptypes
} from '../types';
import { setCalculatedProducts } from '../actions/calculatedProducts';
import ProductDetail from './ProductDetail';
import { MenuContext } from '../App';

const Products = ({
  inputs, products, calculatedProducts, actions
}) => {
  const [modalToOpen, setModalToOpen] = useState(-1);

  useEffect(() => {
    const filteredProducts = getFilteredProducts(products, inputs);
    actions.setCalculatedProducts(filteredProducts);
    // eslint-disable-next-line
  }, [actions.setCalculatedProducts, inputs]);

  const handleMoreDetailsRequest = (product) => {
    setModalToOpen(product);
    ReactGA.modalview('product-detail');
  }

  function renderFilteredProductsList(filteredProducts) {
    return filteredProducts.map((filteredProduct, i) => (
      <li key={filteredProduct.product.id} className="products-list-item">
        <ul>
          <li className="products-list-centered products-list-logo">
            {filteredProduct.product.lender
            && (
            <img
              className="products-list-logo"
              src={require(`../images/products/${filteredProduct.product.lender}.svg`)}
              alt={filteredProduct.product.lender}
            />
            )}
          </li>
          <li className="products-list-dealTerm">
            <p>Deal term</p>
            <p className="products-list-item-title">{formatMonthsToYears(getInitialPeriod(filteredProduct.product.initialPeriodMonths))}</p>
            <p className="products-list-item-subtitle">{getInitialRateType(filteredProduct.product.initialRateType)}</p>
          </li>
          <li className="products-list-initialRate">
            <p>Initial rate</p>
            <p className="products-list-item-title">{formatPercentage(filteredProduct.product.initialRate)}</p>
          </li>
          <li className="products-list-fees">
            <p>Setup fees</p>
            <p className="products-list-item-title">{getFee(filteredProduct.product.fixedAmountFee, filteredProduct.product.variableFee, inputs.financeRequired)}</p>
          </li>
          <li className="products-list-highlight">
            <p>Monthly payment</p>
            <p className="products-list-item-title">{formatCurrency(filteredProduct.initialMonthlyPayment)}</p>
            <p className="products-list-item-subtitle">
              <span>for the first</span>
              <span className="bold">
                {` ${getFirstInitialPeriod(filteredProduct.product.initialPeriodMonths, filteredProduct.product.initialPeriodLimit)} months`}
              </span>
            </p>
          </li>
          <li className="products-list-centered products-list-button">
            <button
              type="button"
              className="button-action products-list-cta"
              onClick={() => handleMoreDetailsRequest(i)}
            >
                More details
            </button>
          </li>
        </ul>
      </li>
    ));
  }

  function renderContent() {
    return (
      <div className="products-body">
        <div className="products-filter">
          <MenuContext.Consumer>
            {(value) => (
              <button
                type="button"
                className="button-filter"
                onClick={() => value.setClassName('showMenu')}
              >
              Refine details
              </button>
            )}
          </MenuContext.Consumer>
        </div>
        {calculatedProducts.length
          ? (
            <>
              <div className="products-list-wrapper">
                <p className="products-list-info">Products are sorted in increasing order of initial monthly payment</p>
                <p className="products-list-results">
                  {`${calculatedProducts.length} Results`}
                </p>
              </div>
              <ul className="products-list">{renderFilteredProductsList(calculatedProducts)}</ul>
            </>
          )
          : (
            <div className="products-notFound">
              <div className="products-notFound-content">
                <p className="products-notFound-title">No results found</p>
                <p>Sorry we haven’t been able to find any products matching those requirements.</p>
              </div>
            </div>
          )}
      </div>
    );
  }

  return (
    <div className="products">
      {modalToOpen >= 0
        && (
        <ProductDetail
          inputs={inputs}
          filteredProduct={calculatedProducts[modalToOpen]}
          setModalToOpen={setModalToOpen}
        />
        )}
      <header className="products-header">
        <h1 className="products-header-title">Compare our best deals and rates</h1>
        <h2 className="products-header-subtitle">Use this calculator to get an idea of what you could finance.</h2>
      </header>
      {renderContent()}
    </div>
  );
};

Products.propTypes = {
  inputs: inputsTypes,
  products: productsTypes,
  calculatedProducts: calculatedProductsProptypes,
  actions: PropTypes.shape({
    setCalculatedProducts: PropTypes.func,
  }),
};

Products.defaultProps = {
  inputs: inputsDefaultTypes,
  products: productsDefaultTypes,
  calculatedProducts: calculatedProductsDefaultProptypes,
  actions: {
    setCalculatedProducts: () => {},
  },
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setCalculatedProducts,
  },
  dispatch)
});

const mapStateToProps = (state) => ({
  inputs: state.inputs,
  products: state.products,
  calculatedProducts: state.calculatedProducts
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
