import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { ProductType } from '../lib/enums';
import { IFG_URL } from '../lib/consts';

const Success = ({ location }) => {
  const params = queryString.parse(location.search);
  let subtitle = 'Thanks for your interest, you should receive a callback shortly. 😊';
  if (params.type === ProductType.BUY_TO_LET) {
    subtitle = 'Thanks for your interest, Precept Finance will be in touch soon. 😊';
  }
  return (
    <div className="success">
      <h1 className="title-xxl">We’ve received your details!</h1>
      <p>{subtitle}</p>
      <a
        href={IFG_URL}
        className="button-action success-button"
      >
        BACK TO ISLAMIC FINANCE GURU
      </a>
    </div>
  );
};

Success.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Success.defaultProps = {
  location: {
    search: null
  },
};

export default Success;
