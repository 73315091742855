export function formatCurrency(num) {
  return new Intl.NumberFormat(
    'en-GB',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'GBP',
      style: 'currency'
    }
  ).format(num);
}

export function formatPercentage(num) {
  return `${parseFloat((num * 100).toFixed(2))}%`;
}

export function monthsToYears(num) {
  return parseInt(num / 12, 10);
}

export function formatMonthsToYears(months) {
  if (months < 12) {
    const monthFormat = months === 1 ? 'month' : 'months';
    return `${months} ${monthFormat}`;
  }
  const years = monthsToYears(months);
  const yearFormat = years === 1 ? 'year' : 'years';
  return `${years} ${yearFormat}`;
}


export function currencyToInt(currency) {
  return parseInt(currency.replace(/[^0-9.]+/g, ''), 10) || 0;
}

export function intToCurrency(number) {
  return parseInt(number, 10).toLocaleString('en-GB');
}

export function getInitialRateType(initialRateType) {
  return initialRateType === 'FIXED' ? 'fixed' : 'discounted variable';
}
